import React from 'react'

import Layout from '../components/Layout'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Footer from '../components/Footer'

import '../templates/ContactPage.css'
import '../components/Footer.css'

const TestContactPage = () => (
    <Layout
        meta={false}
        title={'Test Contact Page'}
    >
        <div className="contact full">
            <div className="thin">
                <FormSimpleAjax />
            </div>
        </div>
        <Footer />
    </Layout>

)

export default TestContactPage